import React from "react";
import RouteCalculator from "../../RouteCalculator";
import RouteExporter from "../../RouteExporter";
import WaypointList from "../../components/WaypointList"


//Material UI
import Divider from '@mui/material/Divider';

import '../../index.css';


const LeftPage = ({ waypoints, setWaypoints, routes, setRoutes }) => {

    return (
        <div className="leftMenu">
            <h2>Step 1</h2>
            <p>Search your favorite spots on the map or add them directy by double clicking on the map.</p>
            <WaypointList waypoints={waypoints} setWaypoints={setWaypoints}/>
            <Divider />
            <h2>Step 2</h2>
            <p>It's time to generate your route.</p>
            <RouteCalculator waypoints={waypoints} setWaypoints={setWaypoints} routes={routes} setRoutes={setRoutes} />
            <Divider />
            <h2>Step 3</h2>
            <p>Export your route.</p>
            <RouteExporter waypoints={waypoints} setWaypoints={setWaypoints} routes={routes} setRoutes={setRoutes} />
            <Divider />
            <div>
                <h4>Support Us:</h4>
                <p>We want to keep this site free but for now we need your help. Many Thanks.</p>
                <form action="https://www.paypal.com/donate" method="post" target="_top">
                    <input type="hidden" name="hosted_button_id" value="RKE6KXQ3B8X5S" />
                    <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                    <img alt="paypal" border="0" src="https://www.paypal.com/en_PT/i/scr/pixel.gif" width="1" height="1" />
                </form>

            </div>
            <p>Copyright © 2024 Pragmatic Pyramid</p>
        </div>
    );
};

export default LeftPage;