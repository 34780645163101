import React from "react";
import { ListItemIcon, MenuItem, MenuList } from '@mui/material';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Typography from '@mui/material/Typography';
import LoginLogoutButton from "../LoginLogoutButton";
import '../../index.css';


const TopMenu = () => {
  return (
    <div className="topmenu">
      <div>
        <Typography variant="h1" gutterBottom sx={{ fontSize: '2rem' }}>
          RideRoutePlanner.com
        </Typography>
        <Typography variant="h2" gutterBottom sx={{ fontSize: '1rem' }}>
          Motorcycle route creation made simple.
        </Typography>
      </div>
      <div className="menu-container">
        <MenuList className="horizontal-menu">
          <MenuItem className="menu-item" component="a" href="https://ride-route-planner.canny.io/" target="_blank">
            <ListItemIcon>
              <CalendarMonthOutlinedIcon className="whiteIcon" />
            </ListItemIcon>
            Roadmap
          </MenuItem>
          <MenuItem className="menu-item" component="a" href="https://ride-route-planner.canny.io/feature-requests" target="_blank">
            <ListItemIcon>
              <LightbulbOutlinedIcon className="whiteIcon" />
            </ListItemIcon>
            Request New Feature
          </MenuItem>
          <LoginLogoutButton />
        </MenuList>
      </div>
    </div>
  );
};

export default TopMenu;
