import React from "react";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useAuth0 } from "@auth0/auth0-react";
import { convertWaypointsToGPXRoute, extractOrderedWaypointsFromRoutes } from "./functions";


import './index.css';

const RouteExporter = ({ waypoints, setWaypoints, routes, setRoutes }) => {
    const { isAuthenticated, loginWithRedirect } = useAuth0();

    const CustomButton = styled(Button)(({ theme }) => ({
        color: '#12343b',
        backgroundColor: '#e1b382', // Change the background color here
        '&:hover': {
            backgroundColor: '#c89666', // Change the hover color here
        },
        marginBottom: '30px',
        marginTop: '30px'
    }));

    const generateFile = (data) => {
        const blob = new Blob([data], { type: 'application/octet-stream' });

        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'riderouteplanner.gpx');

        // Append the link to the body and click it to trigger the download
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }

    const downloadFile = (event) => {

        var data = extractOrderedWaypointsFromRoutes(routes);
        const fileData = convertWaypointsToGPXRoute(data[0].waypoints);
        generateFile(fileData);
    }

    return (
        isAuthenticated ? (
            <CustomButton variant="outlined"
                disabled={(routes != null) ? false : true}
                onClick={downloadFile}
            >Export as GPX</CustomButton>

        ) : (
            <CustomButton variant="outlined"
                onClick={loginWithRedirect}
            >Login to Export Route</CustomButton>

        )

    );
};

export default RouteExporter;