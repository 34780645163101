import React, { useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap, Polyline, useMapEvent } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { StandaloneSearchBox, LoadScript } from '@react-google-maps/api';

const libraries = ['places'];

const GooglePlacesAutocomplete = ({ setPosition }) => {
  const searchBoxRef = useRef(null);

  const onPlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();
    if (places.length > 0) {
      const place = places[0];
      const location = place.geometry.location;
      setPosition({ lat: location.lat(), lng: location.lng(), name: place.name });
    }
  };

  return (
    <StandaloneSearchBox
      onLoad={(ref) => (searchBoxRef.current = ref)}
      onPlacesChanged={onPlacesChanged}
    >
      <input
        type="text"
        placeholder="Search Google Maps"
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          zIndex: 1000,
          padding: '8px',
          width: '300px',
        }}
      />
    </StandaloneSearchBox>
  );
};

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconAnchor: [12, 41]
});
L.Marker.prototype.options.icon = DefaultIcon;




const DoubleClickAddMarker = ({ waypoints, setWaypoints }) => {
  useMapEvent('click', (event) => {
    const { lat, lng } = event.latlng;
    const name = prompt("Enter the name for this waypoint:");

    if (name) {
      setWaypoints([...waypoints, { lat, lng, name }]);
    }
  });

  return null;
};

const LeafletMap = ({ position, setPosition, waypoints, setWaypoints }) => {
  const map = useMap();

  L.DomUtil.addClass(map._container,'crosshair-cursor-enabled');

  const addWaypoint = (waypoint) => {
    setWaypoints([...waypoints, waypoint]);
  };

  useEffect(() => {
    if (position) {
      map.setView([position.lat, position.lng], 13);
    }
  }, [position, map]);

  return position ? (
    <Marker
      position={[position.lat, position.lng]}
      draggable={true}
      eventHandlers={{
        dragend: (event) => {
          position = event.target.getLatLng();
        },
        popupclose: (event) => {
          setPosition(null);
        }

      }}
    >
      <Popup>{position.name || 'Selected Location'}
        <br />
        <button
          onClick={() => addWaypoint(position)}
          style={{
            padding: '4px 8px',
            border: 'none',
            backgroundColor: '#007bff',
            color: 'white',
            borderRadius: '4px',
            cursor: 'pointer',
            marginTop: '5px',
          }}
        >
          Add to Waypoints
        </button>
      </Popup>
    </Marker>
  ) : null;
};

const MapPage = ({ waypoints, setWaypoints, routes, SetRoutes }) => {
  const [position, setPosition] = useState(null);



  //this function returns a list of markers. One for each waypoint.
  function DraggableWaypoint({ index, waypoint }) {

    const handleDragEnd = (event) => {
      const newLatLng = event.target.getLatLng(); // Get the new position after dragging

      // Create a new array with the updated waypoint
      const updatedWaypoints = waypoints.map((wp, i) => {
        if (i === index) {
          return {
            ...wp,
            lat: newLatLng.lat,
            lng: newLatLng.lng,
          };
        }
        return wp;
      });

      // Update the waypoints state with the new array
      setWaypoints(updatedWaypoints);
    }

    if (waypoint != null) {
      return (
        <Marker
          key={index}
          position={{ lat: waypoint.lat, lng: waypoint.lng }}
          draggable={true}
          eventHandlers={{
            dragend: handleDragEnd,
          }}>
          <Popup>{waypoint.name}</Popup>
        </Marker>
      )
    }
    else {
      return null;
    }
  }

  function DrawRoute() {
    const redOptions = { color: 'red' }

    if (routes != null) {
      var latlngs = [];
      routes.map((route) => (
        route.legs.map((leg) => (
          leg.polyline.geoJsonLinestring.coordinates.map((coordinate) => (
            latlngs.push([coordinate[1], coordinate[0]])
          ))
        ))
      ))
    }
    return (
      <Polyline pathOptions={redOptions} positions={latlngs} />
    )
  }

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={libraries} >
      <MapContainer
        center={{ lat: 38.56667, lng: -7.9 }}
        zoom={6}
        scrollWheelZoom={true}
        doubleClickZoom={false}>
        <GooglePlacesAutocomplete setPosition={setPosition} />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        <DoubleClickAddMarker waypoints={waypoints} setWaypoints={setWaypoints} />

        {waypoints != null ? (
          waypoints.map((waypoint, index) => (
            <DraggableWaypoint index={index} waypoint={waypoint} waypoints={waypoints} />
          ))
        ) : null}
        {routes != null ? (<DrawRoute />) : null}
        <LeafletMap position={position} setPosition={setPosition} waypoints={waypoints} setWaypoints={setWaypoints} />
      </MapContainer>
    </LoadScript>
  );
};

export default MapPage;
