import React, { useState } from "react";
import { GenerateRoute } from './functions';

//Material UI
import { Checkbox } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import { LoadingButton } from "@mui/lab";

import './index.css';

const RouteCalculator = ({ waypoints, setRoutes }) => {

    const [routeGenerationPreferences, setRouteGenerationPreferences] = useState({
        "AVOIDHIGHWAYS": false,
        "AVOIDTOLLROADS": false,
        "AVOIDFERRIES": false,
        "OPTIMIZEROUTE": true
    })
    const [isGeneratingRoute, setisGeneratingRoute] = useState(false);


    const CustomButton = styled(LoadingButton)(({ theme }) => ({
        color: '#12343b',
        backgroundColor: '#e1b382', // Change the background color here
        '&:hover': {
            backgroundColor: '#c89666', // Change the hover color here
        },
        marginBottom: '30px',
        marginTop: '30px'
    }));


    const handleButtonClick = (event) => {
        setisGeneratingRoute(true);
        GenerateRoute(waypoints, routeGenerationPreferences).then(response => {
            if (response.length !== 0) {
                setRoutes(response);
            }
            setisGeneratingRoute(false);
        }
        )
    }

    const handlePreferenceChange = (event) => {
        const { name } = event.target;
        setRouteGenerationPreferences((prevData) => ({
            ...prevData,
            [name]: !prevData[name],
        }));
    }

    return (
        <div className="login-logout">
            <h4>Route Generation Parameters:</h4>
            <FormGroup>
                <FormControlLabel control={<Checkbox name="AVOIDHIGHWAYS" checked={routeGenerationPreferences.AVOIDHIGHWAYS} onChange={handlePreferenceChange} />} label="Avoid Highways" />
                <FormControlLabel control={<Checkbox name="AVOIDTOLLROADS" checked={routeGenerationPreferences.AVOIDTOLLROADS} onChange={handlePreferenceChange} />} label="Avoid Toll Roads" />
                <FormControlLabel control={<Checkbox name="AVOIDFERRIES" checked={routeGenerationPreferences.AVOIDFERRIES} onChange={handlePreferenceChange} />} label="Avoid Ferries" />
            </FormGroup>
            <h4>Route Optimization:</h4>
            <FormGroup row>
                <Grid container alignItems="center">
                    <Grid item>
                        <FormControlLabel control={<Checkbox name="OPTIMIZEROUTE" checked={routeGenerationPreferences.OPTIMIZEROUTE} onChange={handlePreferenceChange} />} label="Optimize" />
                    </Grid>
                    <Grid item>
                        <Tooltip title="When you check the 'Optimize' option, we will automatically reorder your waypoints to create the most efficient route possible. Start and End waypoints will be kept in order.">
                            <InfoIcon />
                        </Tooltip>
                    </Grid>
                </Grid>
            </FormGroup>
            <CustomButton
                variant="outlined"
                loading={isGeneratingRoute ? true : false}
                disabled={(waypoints !== null) ? false : true}
                loadingIndicator={<CircularProgress color="inherit" size={16} />}
                onClick={handleButtonClick}>Generate</CustomButton>
        </div>
    );
};

export default RouteCalculator;