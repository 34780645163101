import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';


const Auth0ProviderWithHistory = ({ children }) => {
    const domain = "riderouteplanner.eu.auth0.com";
    const clientId = "V1aLImHclgZhqlNeNtq0CpLO76w1e3Yp";

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                redirect_uri: window.location.origin
              }}
        >
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithHistory;