import React, { useState } from "react";
import TopMenu from "./components/Menus/TopMenu";
import LeftPage from "./components/Pages/LeftPage";
import MapPage from "./components/Pages/MapPage";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './index.css';
import usePersistentState from "./auth/usePersistentState";


const theme = createTheme({
  palette: {
    primary: {
      main: '#12343b',
    },
    secondary: {
      main: '#12343b',
    },
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        "root": {
          "&.Mui-checked": {
            "color": "#12343b"
          }
        }
      },
    },
  },
});


const App = () => {

  const [waypoints, setWaypoints] = usePersistentState('waypoints',[])
  const [routes, setRoutes] = usePersistentState('routes',null);

  return (
    <ThemeProvider theme={theme}>
      <div>
        <TopMenu />
        <div style={{ display: 'flex', height: '100vh' }}>
          <LeftPage waypoints={waypoints} setWaypoints={setWaypoints} routes={routes} setRoutes={setRoutes} />
          {/* Right Column with OpenStreetMap */}
          <div style={{ flex: 1 }}>
            <MapPage waypoints={waypoints} setWaypoints={setWaypoints} routes={routes} setRoutes={setRoutes} />
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default App;